var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"animated fadeIn"},[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',[_c('i',{staticClass:"icon-note"}),_vm._v(" Edit Jewelry Order "),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"jewelry-orders","rel":"noreferrer noopener"}},[_c('small',{staticClass:"text-muted"},[_vm._v("Back")])])])]),_c('b-card-body',[(_vm.isLoading == true)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"sk-three-bounce"},[_c('div',{staticClass:"sk-child sk-bounce1"}),_c('div',{staticClass:"sk-child sk-bounce2"}),_c('div',{staticClass:"sk-child sk-bounce3"})])]):_vm._e(),_c('b-row',[_c('b-col',[_c('b-form',{attrs:{"novalidate":"","action":"https://vuejs.org/"},on:{"submit":function($event){$event.preventDefault();return _vm.postOrder.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"id":"invoice","label":"Nomor Invoice","label-for":"invoice"}},[_c('b-form-input',{attrs:{"id":"invoice","type":"text","aria-describedby":"input1LiveFeedback4","placeholder":"Nomor Invoice","disabled":"","autocomplete":"address"},model:{value:(_vm.form.invoice),callback:function ($$v) {_vm.$set(_vm.form, "invoice", $$v)},expression:"form.invoice"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input1LiveFeedback4"}},[_vm._v(" This is a required field and must be valid e-mail address ")])],1),_c('b-form-group',{attrs:{"id":"provider","label":"Metode Pengiriman","label-for":"providers"}},[_c('b-form-select',{attrs:{"id":"providers","plain":true,"autocomplete":"given-name","autofocus":"","disabled":"","options":[
                  { value: 'rpx', text: 'RPX' },
                  { value: 'paxel', text: 'PAXEL' },
                  { value: 'sap', text: 'SAP' } ]},model:{value:(_vm.form.provider),callback:function ($$v) {_vm.$set(_vm.form, "provider", $$v)},expression:"form.provider"}})],1),_c('b-form-group',{attrs:{"id":"status","label":"Status Pengiriman","label-for":"status"}},[_c('b-form-select',{attrs:{"id":"status","plain":true,"state":_vm.chkState('status'),"aria-describedby":"shippingAddressFeedback","autocomplete":"given-name","autofocus":"","options":[
                  { value: '', text: 'Pilih Status Pengiriman' },
                  { value: 'processing', text: 'Sedang Diproses' },
                  { value: 'awaiting-shipment', text: 'Dalam Pengiriman' },
                  { value: 'pending', text: 'Menunggu Pembayaran' },
                  { value: 'cancelled', text: 'Dibatalkan' } ]},model:{value:(_vm.$v.form.status.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.status, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.form.status.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"shippingAddressFeedback"}},[(!this.$v.form.status.required)?_c('span',[_vm._v(" Status Pengiriman tidak boleh kosong ")]):_vm._e()])],1),_c('b-form-group',{attrs:{"id":"tracking_no","label":"Nomor Resi","label-for":"tracking_no"}},[_c('b-form-input',{attrs:{"id":"tracking_no","type":"text","placeholder":"Nomor Resi","autocomplete":"tracking_no"},model:{value:(_vm.form.tracking_no),callback:function ($$v) {_vm.$set(_vm.form, "tracking_no", $$v)},expression:"form.tracking_no"}})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Submit ")])],1),_c('br')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }