<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-card-header>
        <i class="icon-note"></i> Edit Jewelry Order
        <div class="card-header-actions">
          <a
            class="card-header-action"
            href="jewelry-orders"
            rel="noreferrer noopener"
          >
            <small class="text-muted">Back</small>
          </a>
        </div>
      </b-card-header>
      <b-card-body>
        <div class="loading" v-if="isLoading == true">
          <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
          </div>
        </div>
        <b-row>
          <b-col>
            <!--<h6>Simple Form</h6>-->
            <b-form
              v-on:submit.prevent="postOrder"
              novalidate
              action="https://vuejs.org/"
            >
              <b-form-group
                id="invoice"
                label="Nomor Invoice"
                label-for="invoice"
              >
                <b-form-input
                  id="invoice"
                  type="text"
                  v-model="form.invoice"
                  aria-describedby="input1LiveFeedback4"
                  placeholder="Nomor Invoice"
                  disabled
                  autocomplete="address"
                />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  This is a required field and must be valid e-mail address
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="provider"
                label="Metode Pengiriman"
                label-for="providers"
              >
                <b-form-select
                  id="providers"
                  :plain="true"
                  v-model="form.provider"
                  autocomplete="given-name"
                  autofocus
                  disabled
                  :options="[
                    { value: 'rpx', text: 'RPX' },
                    { value: 'paxel', text: 'PAXEL' },
                    { value: 'sap', text: 'SAP' },
                  ]"
                >
                </b-form-select>
              </b-form-group>
              <!-- <b-form-group id="status_payment"
                            label="Status Pembayaran"
                            label-for="status_payment">
                <b-form-select id="status_payment"
                              :plain="true"
                              v-model.lazy.trim="$v.form.payment_status.$model"
                              :state="chkState('payment_status')"
                              aria-describedby="statusPaymentFeedback"
                              autocomplete='given-name'
                              autofocus
                              :options="[{value: '', text:'Pilih Status Pembayaran'},
                              {value: 'pending', text: 'Menunggu Pembayaran'},
                              {value: 'paid', text: 'Sudah Dibayar'},
                              {value: 'cancelled', text: 'Dibatalkan'}]">
                </b-form-select>
                <b-form-invalid-feedback id="statusPaymentFeedback" v-for="(error , index) in errors.form.payment_status" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="shipping_cost"
                            label="Harga Pengiriman"
                            label-for="shipping_cost">
                <b-form-input id="shipping_cost"
                              type="number"
                              v-model.trim="$v.form.shipping_cost.$model"
                              :state="chkState('shipping_cost')"
                              aria-describedby="shippingCostFeedback"
                              placeholder="Harga Pengiriman"
                              autocomplete='address' />
                <b-form-invalid-feedback id="shippingCostFeedback" v-for="(error , index) in errors.form.shipping_cost" :key="index">
                  - {{ error }} <br>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="payment"
                            label="Payment Method"
                            label-for="payment">
                <b-form-input id="payment"
                              type="text"
                              v-model.trim="form.payment_name"
                              aria-describedby="input1LiveFeedback4"
                              placeholder="Metode Pembayaran"
                              disabled
                              autocomplete='address' />
                <b-form-invalid-feedback id="input1LiveFeedback4">
                  This is a required field and must be valid e-mail address
                </b-form-invalid-feedback>
              </b-form-group> -->
              <b-form-group
                id="status"
                label="Status Pengiriman"
                label-for="status"
              >
                <b-form-select
                  id="status"
                  :plain="true"
                  v-model.lazy.trim="$v.form.status.$model"
                  :state="chkState('status')"
                  aria-describedby="shippingAddressFeedback"
                  autocomplete="given-name"
                  autofocus
                  :options="[
                    { value: '', text: 'Pilih Status Pengiriman' },
                    { value: 'processing', text: 'Sedang Diproses' },
                    { value: 'awaiting-shipment', text: 'Dalam Pengiriman' },
                    { value: 'pending', text: 'Menunggu Pembayaran' },
                    { value: 'cancelled', text: 'Dibatalkan' },
                  ]"
                >
                </b-form-select>
                <b-form-invalid-feedback id="shippingAddressFeedback">
                  <span v-if="!this.$v.form.status.required">
                    Status Pengiriman tidak boleh kosong
                  </span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="tracking_no"
                label="Nomor Resi"
                label-for="tracking_no"
              >
                <b-form-input
                  id="tracking_no"
                  type="text"
                  v-model="form.tracking_no"
                  placeholder="Nomor Resi"
                  autocomplete="tracking_no"
                />
              </b-form-group>
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-form>
            <br />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ValidationForms",
  data() {
    return {
      form: {
        status: "",
        provider: "",
        tracking_no: "",
        invoice: "",
      },
      levelStatus: process.env.VUE_APP_LEVEL,
      errors: {
        message: [],
        status: [],
        code: "",
        headers: [],
        form: {
          payment_status: [],
          shipping_fee: [],
          tracking_no: [],
          note_to_customer: [],
          customer_note: [],
          status: [],
        },
      },
      items: [],
      product: "",
      postal_code: "",
      products: [],
      payment: "",
      payments_method: [],
      tracking: "",
      isLoading: false,
    };
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      status: {
        required,
      },
    },
  },
  methods: {
    postOrder() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("payment_status", this.form.payment_status);
        formData.append("shipping_cost", this.form.shipping_cost);
        formData.append("status", this.form.status);
        formData.append(
          "tracking_no",
          this.form.tracking_no === null ? "" : this.form.tracking_no
        );

        this.$http
          .post(`jewelry-order/update/` + this.$route.params.id, formData)
          .then(() => {
            this.isLoading = false;
            this.$router.push({ name: "Jewelry Orders" });
            this.$toasted.success("Jewelry Order successfully updated!");
          })
          .catch((error) => {
            if (error.response) {
              this.isLoading = false;
              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
              if (this.errors.status == 422) {
                this.$toasted.error(
                  "Please fill out the form that must be required"
                );
                this.errors.message = error.response.data.meta.message;
                this.errors.form.payment_status =
                  this.errors.message.payment_status;
                this.errors.form.shipping_cost =
                  this.errors.message.shipping_cost;
                this.errors.form.status = this.errors.message.status;
                this.errors.form.tracking_no = this.errors.message.tracking_no;
              }
            }
          });
      }
    },

    onReset() {
      // Reset validation
      this.form = Object.assign({});
      this.submitted = false;
      this.$nextTick(() => {
        this.$v.$reset();
        this.feedBack = "secondary";
      });
    },
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some((child) => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
  created() {
    this.$http
      .get(`jewelry-order/` + this.$route.params.id)
      .then((result) => {
        let res = result.data.data;
        this.form.invoice = res.invoice_group;
        this.form.status = res.shipping_status;
        this.form.tracking_no = res.tracking_no;
        this.form.provider = res.shipping_provider;
      })
      .catch((error) => {
        if (error.response) {
          this.errors.code = error.response.data.meta.code;
          this.errors.status = error.response.status;
          this.errors.headers = error.response.headers;
        }
      });
  },
};
</script>

<style scoped>
.btn.disabled {
  cursor: auto;
}
</style>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
